import { Navbar, Nav, NavDropdown, Container, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import EventsData from "../data/events.json";
import GLData from "../data/gl.json";
import { useNavigate } from "react-router-dom";

export default () => {
  const navigate = useNavigate();
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand>
          <img
            src="/logo192.png"
            style={{ height: "30px", marginRight: "10px" }}
          />
          NSSC Admin
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>All Registrations</Nav.Link>
            </LinkContainer>
            <NavDropdown title="Event Registrations" id="basic-nav-dropdown">
              {Object.keys(EventsData).map((val) => (
                <LinkContainer to={"/event/" + val + "/"} key={val}>
                  <NavDropdown.Item key={val}>
                    {EventsData[val].name}
                  </NavDropdown.Item>
                </LinkContainer>
              ))}
            </NavDropdown>
            <NavDropdown title="Student Ambassadors" id="basic-nav-dropdown">
              <LinkContainer to="/SA/">
                <NavDropdown.Item>
                  Selected Student Ambassadors
                </NavDropdown.Item>
              </LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to="/SA/reg/">
                <NavDropdown.Item>
                  Student Ambassador Registrations
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to="/payments">
              <Nav.Link>Payments</Nav.Link>
            </LinkContainer>
            <NavDropdown title="GL Attendance" id="basic-nav-dropdown">
              {Object.keys(GLData).map((val) => (
                <LinkContainer to={"/attendance/" + val + "/"} key={val}>
                  <NavDropdown.Item key={val}>
                    {GLData[val].guestName}
                  </NavDropdown.Item>
                </LinkContainer>
              ))}
            </NavDropdown>
          </Nav>
          {localStorage.getItem("nssc-admin-token") && (
            <Button
              variant="danger"
              className="ms-2"
              onClick={(e) => {
                e.preventDefault();
                localStorage.removeItem("nssc-admin-token");
                navigate("/login");
              }}
            >
              <i className="fas fa-sign-out-alt" /> Logout
            </Button>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
