import { toast } from "react-toastify";
import Axios from "axios";

async function callAxios(method, endpoint, data) {
  if (method == "GET") {
    return Axios.get(
      process.env.REACT_APP_BACKEND_URL + endpoint + "?" + data,
      {
        headers: {
          authorization: localStorage.getItem("nssc-admin-token"),
        },
      }
    );
  } else if (method == "POST") {
    if (endpoint == "/admin/login") {
      return Axios.post(process.env.REACT_APP_BACKEND_URL + endpoint, data);
    }
    return Axios.post(process.env.REACT_APP_BACKEND_URL + endpoint, data, {
      headers: {
        authorization: localStorage.getItem("nssc-admin-token"),
      },
    });
  } else if (method == "DELETE") {
    return Axios.delete(process.env.REACT_APP_BACKEND_URL + endpoint, {
      headers: {
        authorization: localStorage.getItem("nssc-admin-token"),
      },
    });
  }
}

export async function callBackend({
  method,
  endpoint,
  setLoading,
  data,
  onSuccess,
}) {
  const toastDict = {
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  };

  setLoading && setLoading(true);
  await callAxios(method, endpoint, data)
    .then((response) => {
      if (response.data.status == 200) {
        setLoading && setLoading(false);
        method != "GET" && toast.success(response.data.message, toastDict);
        onSuccess && onSuccess(response.data);
      } else if (response.data.status == 401) {
        setLoading && setLoading(false);
        toast.error(response.data.message, toastDict);
        localStorage.removeItem("nssc-admin-token");
        window.location.href = "/login";
      } else if (response.data.status == 800) {
        setLoading && setLoading(false);
        toast.error(response.data.message, toastDict);
      } else {
        setLoading && setLoading(false);
        toast.error("Something went wrong! Please try again.", toastDict);
      }
    })
    .catch((error) => {
      setLoading && setLoading(false);
      toast.error(
        "Something went wrong! Please check your internet connection.",
        toastDict
      );
    });
}
