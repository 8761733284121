import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { callBackend } from "../components/callBackend";
import { Button, Container } from "react-bootstrap";

export default function SADetails() {
	const { saID } = useParams();
	const [sa, setSA] = useState(null);

	const getDetails = async () => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}
		callBackend({
			method: "GET",
			endpoint: "/admin/sa/" + saID,
			onSuccess: (res) => {
				setSA(res.data);
			},
		});
	};

	useEffect(() => {
		getDetails();
	}, [saID]);

	return (
		<Container
			style={{
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<h1 className="text-center">
				<u>{sa && sa.fName + " " + sa.lName}</u>
			</h1>
			{sa && (
				<div>
					<h2>SAID - {saID}</h2>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							flexWrap: "wrap",
							gap: "10px",
						}}
					>
						<div>
							<p>
								Email :{" "}
								<a href={"mailto:" + sa.email}>{sa.email}</a>
							</p>
							<p>
								Phone :{" "}
								<a href={"tel:" + sa.phone}>{sa.phone}</a>
							</p>
							<p>Gender : {sa.gender}</p>
						</div>
						<div>
							<p>College : {sa.college}</p>
							<p>Level : {sa.level}</p>
							<p>
								City/State : {sa.city}, {sa.state}
							</p>
						</div>
						<div>
							<p>Successful Payments: {sa.count}</p>
							<p>Age : {sa.age}</p>
							<p>Count : {sa.pids.length}</p>
						</div>
						<div>
							Registration through refernece link :{" "}
							<a
								href={`https://nssc.in/register/${saID}`}
								target="_blank"
								rel="noreferrer"
							>
								{`https://nssc.in/register/${saID}`}
							</a>
						</div>
						<div style={{ width: "100%" }}>
							PIDs:
							<div
								style={{
									display: "flex",
									flexWrap: "wrap",
									justifyContent: "space-between",
								}}
							>
								{sa.pids.map((pid, idx) => (
									<Link
										key={idx}
										to={"/participant/" + pid}
										style={{
											marginRight: "5px",
											marginBottom: "5px",
										}}
									>
										<Button variant="outline-primary">
											{pid}
										</Button>
									</Link>
								))}
							</div>
						</div>
					</div>
				</div>
			)}
		</Container>
	);
}
