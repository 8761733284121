import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useZxing } from "react-zxing";
import { callBackend } from "../components/callBackend";
import GLData from "../data/gl.json";

export default function Attendance() {
  const { gl } = useParams();

  const { ref } = useZxing({
    onDecodeResult(result) {
      document.getElementsByName("pid")[0].value = result.getText();
      glAttendance();
    },
  });

  const glAttendance = async () => {
    if (confirm("Are you sure you want to mark attendance?")) {
      callBackend({
        method: "POST",
        endpoint: "/admin/gl/" + gl,
        data: {
          pid: document.getElementsByName("pid")[0].value,
        },
        onSuccess: (res) => {
          document.getElementsByName("pid")[0].value = "";
        },
      });
    }
  };

  return (
    <Container
      style={{
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <h1 className="text-center">
        <u>Attendance for {GLData[gl].guestName}</u>
      </h1>
      <br />
      <center>
        <div
          style={{
            gap: "10px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input type="text" placeholder="Enter PID" name="pid" />
          <button className="btn btn-primary" onClick={glAttendance}>
            Submit
          </button>
        </div>
        <br />
        <video
          ref={ref}
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "500px",
            maxHeight: "300px",
          }}
        />
      </center>
    </Container>
  );
}
