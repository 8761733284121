import { useState, useEffect } from "react";
import { callBackend } from "../components/callBackend";
import { Container } from "react-bootstrap";
import DataTable from "../components/DataTable";
import DownloadButton from "../components/DownloadButton";

export default function AllSAReg() {
	const [count, setCount] = useState(0);
	const [SAData, setSAData] = useState([]);

	let pref = JSON.parse(localStorage.getItem("nssc-admin-pref"));
	const [tableControls, setTableControls] = useState(
		pref && pref.pageID == 2
			? pref
			: {
					page: 1,
					length: 20,
					sortField: "_id",
					sortDir: 1,
			  }
	);

	const columns = {
		_id: "Serial Number",
		saID: "SA ID",
		fName: "First Name",
		lName: "Last Name",
		email: "Email",
		phone: "Phone",
		college: "College/School",
		city: "City",
		state: "State/UT",
		gender: "Gender",
		count: "Registrations",
		paidCount: "Payments",
	};

	let downloadHeaders = [
		{ label: "SA ID", key: "saID" },
		{ label: "First Name", key: "fName" },
		{ label: "Last Name", key: "lName" },
		{ label: "Email Address", key: "email" },
		{ label: "Phone Number", key: "phone" },
		{ label: "Gender", key: "gender" },
		{ label: "Age", key: "age" },
		{ label: "Level of Study", key: "level" },
		{ label: "Name of Institution", key: "college" },
		{ label: "City", key: "city" },
		{ label: "State/UT", key: "state" },
		{ label: "Registrations", key: "count" },
		{ label: "Payments", key: "paidCount" },
		{ label: "PIDs", key: "pids" },
	];

	useEffect(() => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}
		callBackend({
			method: "GET",
			endpoint: "/admin/sa/selected",
			data:
				"sortField=" +
				tableControls.sortField +
				"&sortDir=" +
				tableControls.sortDir +
				"&first=" +
				(0 + tableControls.length * (tableControls.page - 1)) +
				"&limit=" +
				tableControls.length,
			onSuccess: (res) => {
				setSAData(res.data);
				setCount(res.count);
			},
		});
		localStorage.setItem(
			"nssc-admin-pref",
			JSON.stringify({ ...tableControls, pageID: 2 })
		);
	}, [tableControls]);

	return (
		<Container
			style={{
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<h1>Student Ambassadors</h1>
			<DataTable
				columns={columns}
				data={SAData}
				count={count}
				tableControls={tableControls}
				setTableControls={setTableControls}
				downloadButton={
					<DownloadButton
						endpoint={"/admin/download/sa/"}
						downloadHeaders={downloadHeaders}
					/>
				}
			/>
		</Container>
	);
}
