import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { callBackend } from "../components/callBackend";
import { Button, Container } from "react-bootstrap";
import EventsData from "../data/events.json";
import GLData from "../data/gl.json";
import { toast } from "react-toastify";

export default function ParticipantDetails() {
	const { pid } = useParams();
	const [role, setRole] = useState(0);
	const [message, setMessage] = useState("");

	const [participant, setParticipant] = useState(null);

	const [paymentUpdate, setPaymentUpdate] = useState({
		pid: pid,
		size: "",
	});

	const [hall, setHall] = useState("");

	const toastDict = {
		position: "top-center",
		autoClose: 3000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "dark",
	};

	const getDetails = async () => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}
		callBackend({
			method: "GET",
			endpoint: "/admin/reg/" + pid,
			onSuccess: (res) => {
				setParticipant(res.data);
				setRole(res.role);
			},
		});
	};

	const getTShirt = async () => {
		if (!localStorage.getItem("nssc-admin-token")) {
			window.location.href = "/login";
		}
		callBackend({
			method: "GET",
			endpoint: "/admin/reg/tshirt/" + pid,
			onSuccess: (res) => {
				if (res.data == 0) {
					setMessage("");
				} else if (res.data == 1) {
					setMessage("T-Shirt Not Required");
				} else if (res.data == 2) {
					setMessage("T-Shirt Not Given");
				} else if (res.data == 3) {
					setMessage("T-Shirt Given");
				} else {
					console.log("Error");
				}
			},
		});
	};

	const deregister = async (eid) => {
		if (role <= 1) {
			toast.error("You are not authorized to perform this action!", toastDict);
			return;
		}

		if (confirm("Are you sure you want to deregister?")) {
			callBackend({
				method: "POST",
				endpoint: "/admin/events/delete/",
				data: {
					eid: eid,
					pid: pid,
				},
				onSuccess: (res) => {
					getDetails();
				},
			});
		}
	};

	const glAttendance = async (gl) => {
		if (role <= 1) {
			toast.error("You are not authorized to perform this action", toastDict);
			return;
		}

		if (confirm("Are you sure you want to mark attendance?")) {
			callBackend({
				method: "POST",
				endpoint: "/admin/gl/" + gl,
				data: {
					pid: pid,
				},
				onSuccess: (res) => {
					getDetails();
				},
			});
		}
	};

	const tShirtGiving = async () => {
		if (role <= 1) {
			toast.error("You are not authorized to perform this action", toastDict);
			return;
		}

		if (participant.payment.status < 1) return;
		if (participant.tShirt == null) return;

		if (confirm("Have you given the TShirt to the participant?")) {
			callBackend({
				method: "POST",
				endpoint: "/admin/reg/tshirt/" + pid,
				data: {},
				onSuccess: (res) => {
					setMessage(res.data);
					getTShirt();
				},
			});
		} else {
			getTShirt();
		}
	};

	const reportParticipant = async () => {
		if (role <= 2) {
			toast.error("You are not authorized to perform this action", toastDict);
			return;
		}

		if (
			confirm(
				"Are you sure you want to mark participant reporting at reg desk?"
			)
		) {
			callBackend({
				method: "POST",
				endpoint: "/admin/reg/attendance/" + pid,
				data: {},
				onSuccess: (res) => {
					getDetails();
					tShirtGiving();
				},
			});
		}
	};

	const updatePayment = async () => {
		if (role <= 2) {
			toast.error("You are not authorized to perform this action", toastDict);
			return;
		}

		if (
			confirm("Are you sure you want to confirm payment for this participant?")
		) {
			callBackend({
				method: "POST",
				endpoint: "/admin/payment/",
				data: paymentUpdate,
				onSuccess: (res) => {
					getDetails();
				},
			});
		}
	};

	const regDesk = participant ? (
		participant.payment.status == 2 ? (
			<>
				<b style={{ color: "green" }}>
					Partcipant Reported {message ? " | " + message : ""}
				</b>
				<button
					className="btn btn-danger"
					onClick={tShirtGiving}
					disabled={
						!(
							participant.payment.status == 2 &&
							participant.tShirtGiven == false &&
							participant.tShirt != null
						)
					}
				>
					TShirt Given
				</button>
			</>
		) : role > 2 ? (
			<button className="btn btn-primary" onClick={reportParticipant}>
				Report at RegDesk
			</button>
		) : (
			<b style={{ color: "red" }}>Participant not reported</b>
		)
	) : null;

	const paymentBox = participant ? (
		<div
			style={{
				fontWeight: "bold",
				color: participant.payment.status >= 1 ? "green" : "red",
			}}
		>
			{participant.payment.status >= 1 ? (
				"Payment on " +
				participant.payment.time +
				" | Size : " +
				(participant.tShirt ? participant.tShirt : "No T-Shirt")
			) : (
				<div
					style={{
						float: "right",
						marginLeft: "10px",
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						flexWrap: "wrap",
						gap: "10px",
						marginBottom: "10px",
					}}
				>
					Payment Pending
					{role > 3 && (
						<>
							<select
								onChange={(e) => {
									setPaymentUpdate({
										...paymentUpdate,
										size: e.target.value,
									});
								}}
							>
								<option value="">Select T-Shirt Size</option>
								<option value="N">No TShirt</option>
								<option value="S">S</option>
								<option value="M">M</option>
								<option value="L">L</option>
								<option value="XL">XL</option>
								<option value="XXL">XXL</option>
							</select>
							<Button variant="primary" onClick={updatePayment}>
								Update Payment
							</Button>
						</>
					)}
				</div>
			)}
		</div>
	) : null;

	useEffect(() => {
		getDetails();
		getTShirt();
	}, [pid]);

	return (
		<Container
			style={{
				marginTop: "20px",
				marginBottom: "20px",
			}}
		>
			<h1 className="text-center">
				<u>{participant && participant.fName + " " + participant.lName}</u>
			</h1>
			{participant && (
				<div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							flexWrap: "wrap",
						}}
					>
						<h2>PID - {pid}</h2>
						{regDesk}
						{paymentBox}
					</div>
					{role >= 2 &&
					participant.payment.status == 2 &&
					participant.hall == null ? (
						<div
							style={{
								marginBlock: "10px",
								display: "flex",
								flexDirection: "column",
								justifyContent: "space-between",
								alignItems: "center",
								flexWrap: "wrap",
								gap: "10px",
							}}
						>
							<select
								onChange={(e) => {
									setHall(e.target.value);
								}}
							>
								<option value="">Select Hall</option>
								<option value="N">No Accomodation</option>
								<option value="MMM">MMM</option>
								<option value="GKH">Gokhale</option>
								<option value="AZAD">Azad</option>
								<option value="PAT">Patel</option>
								<option value="BCR">BC Roy</option>
								<option value="HJB">HJB</option>
								<option value="JCB">JCB</option>
								<option value="LLR">LLR</option>
								<option value="BRH">BRH</option>
								<option value="SNVH">SNVH</option>
								<option value="RLB">RLB</option>
							</select>
							<button
								className="btn btn-primary"
								onClick={() => {
									callBackend({
										method: "POST",
										endpoint: "/admin/hall/",
										data: {
											hall: hall,
											pid: pid,
										},
										onSuccess: (res) => {
											getDetails();
										},
									});
								}}
							>
								Update Hall
							</button>
						</div>
					) : role >= 2 &&
					  participant.payment.status == 2 &&
					  participant.hall != null ? (
						<h3
							style={{
								marginBlock: "2rem",
								textAlign: "center",
							}}
						>
							{" "}
							<b>Hall of Residence - </b> {participant.hall}
						</h3>
					) : null}

					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							flexWrap: "wrap",
						}}
					>
						<div>
							<p>
								Email :{" "}
								<a href={"mailto:" + participant.email}>{participant.email}</a>
							</p>
							<p>
								Phone :{" "}
								<a href={"tel:" + participant.phone}>{participant.phone}</a>
							</p>
							<p>
								Gender : {participant.gender} | Date of Birth -{" "}
								{participant.dob}
							</p>
						</div>
						<div>
							<p>College : {participant.college}</p>
							<p>Level : {participant.level}</p>
							<p>
								City/State : {participant.city}, {participant.state}
							</p>
						</div>
						<div>
							<p>Roll Number : {participant.roll}</p>
							<p>
								Student Ambassador :{" "}
								<Link to={"/sa/" + participant.saID}>{participant.saID}</Link>
							</p>
							<p>
								Verification Status :{" "}
								{participant.verified.status ? "Verified" : "Not Verified"}
							</p>
						</div>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							flexWrap: "wrap",
						}}
					>
						{Object.keys(participant.events).map((event) => {
							return (
								<div
									key={event}
									style={{
										width: "30%",
										height: "200px",
										margin: "5px",
										padding: "5px",
										border: "1px solid black",
										borderRadius: "5px",
									}}
								>
									{event <= 3 ? (
										<p>
											{event}. {EventsData[event].name}
											<br />
											{participant.events[event].status == 2
												? "Submitted"
												: participant.events[event].status == 1
												? "Registered"
												: "Not Registered"}
											{participant.events[event].status == 2 && (
												<>
													<br />
													{event == 2 ? (
														"Hackerank Username - " +
														participant.events[event].submission
													) : (
														<a
															href={participant.events[event].submission}
															target="_blank"
															rel="noreferrer"
														>
															Submission
														</a>
													)}
												</>
											)}
											{participant.events[event].status != 0 && (
												<>
													<br />
													<Button
														variant="danger"
														onClick={() => {
															deregister(event);
														}}
													>
														De-Regsiter
													</Button>
												</>
											)}
										</p>
									) : (
										<p>
											{event}. {EventsData[event].name}
											{participant.events[event] ? (
												<>
													<br />
													Team ID - {participant.events[event].teamID}
													<br />
													Team Name - {participant.events[event].teamName}
													<br />
													PIDS -{" "}
													{participant.events[event].pids.map((pid) => (
														<Link
															key={pid}
															to={"/participant/" + pid}
															style={{ marginRight: "5px" }}
														>
															{pid}
														</Link>
													))}
													<br />
													{participant.events[event].subLink ? (
														<a
															href={participant.events[event].subLink}
															target="_blank"
															rel="noreferrer"
														>
															Submission
														</a>
													) : (
														"Not Submitted"
													)}
													<br />
													<Button
														variant="danger"
														onClick={() => {
															deregister(event);
														}}
													>
														De-Regsiter
													</Button>
												</>
											) : (
												" - No Team Registered"
											)}
										</p>
									)}
								</div>
							);
						})}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							flexWrap: "wrap",
						}}
					>
						{Object.keys(participant.gl).map((gl) => {
							if (gl > 7) {
								return null;
							}
							return (
								<div
									key={gl}
									style={{
										width: "30%",
										height: "100px",
										margin: "5px",
										padding: "5px",
										border: "1px solid black",
										borderRadius: "5px",
									}}
								>
									<p>
										{gl}. {GLData[gl].guestName} -{" "}
										{participant.gl[gl]
											? participant.gl[gl] == 2
												? "Attended"
												: "Registered"
											: "Not Registered"}
										<br />
										{participant.gl[gl] != 2 && (
											<Button
												variant="primary"
												onClick={() => {
													glAttendance(gl);
												}}
											>
												Mark Attendance
											</Button>
										)}
									</p>
								</div>
							);
						})}
					</div>
				</div>
			)}
		</Container>
	);
}
