import { useState } from "react";
import { CSVLink } from "react-csv";
import { Button, Spinner } from "react-bootstrap";
import { callBackend } from "./callBackend";

export default function DownloadButton(props) {
  const { endpoint, downloadHeaders } = props;

  const [downloadData, setDownloadData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDownloadData = () => {
    if (!localStorage.getItem("nssc-admin-token")) {
      window.location.href = "/login";
    }
    callBackend({
      method: "GET",
      endpoint: endpoint,
      setLoading,
      onSuccess: (res) => {
        setDownloadData(res.data);
        setTimeout(() => {
          document.getElementsByClassName("csv-download-button")[0].click();
        }, 1000);
      },
    });
  };

  return downloadData ? (
    <CSVLink
      className="btn btn-primary csv-download-button"
      filename={"Downloaded Data - " + new Date().toDateString() + ".csv"}
      data={downloadData}
      headers={downloadHeaders}
      onClick={() => {
        setDownloadData(null);
      }}
    >
      Download Data
    </CSVLink>
  ) : (
    <Button className="primary" onClick={getDownloadData}>
      {loading ? <Spinner animation="border" size="sm" /> : "Download Data"}
    </Button>
  );
}
